<template>
  <div class="cookies" v-if="!cookiesAccepted">
    <div style="font-size: 0.9rem">
      Τα cookies είναι σημαντικά για την εύρυθμη λειτουργία του ιστοτόπου
      Stoferno.gr και για την βελτίωση της online εμπειρίας σας. Αναλυτικότερα
      <label
        @click="goToCookiesRouter"
        style="text-decoration: underline; cursor: pointer"
        >εδώ</label
      >
    </div>
    <v-btn @click="acceptCookies" small style="text-transform: initial"
      >Αποδοχή</v-btn
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookiesAccepted: false,
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "accepted");
      this.cookiesAccepted = true;
    },
    goToCookiesRouter() {
      this.$router.push({ path: "legal/cookies-policy-el" });
    },
  },
  created() {
    this.cookiesAccepted = !!localStorage.getItem("cookiesAccepted");
  },
};
</script>

<style>
.cookies {
  text-align: left;
  position: fixed;
  bottom: 0;
  z-index: 10000000000;
  background-color: rgb(75, 75, 75);
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.386);
  color: white;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 20px;
}
</style>
