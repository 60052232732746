<template>
  <v-app>
    <!-- <v-app-bar app color="orange" dark> </v-app-bar> -->

    <!-- Main. All componets are rendering here -->
    <v-main>
      <v-container fluid style="padding: 0">
        <router-view />
        <transition name="fade"> </transition>
        <cookies-bar />
        <!-- <SeasonalDrops></SeasonalDrops> -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import CookiesBar from "../components/Shell-Components/CookiesBar.vue";
// import SeasonalDrops from "@/components/seasonalDrops";
export default {
  components: {
    CookiesBar,
    //  SeasonalDrops
  },
  data() {
    return {
      intercomToken: null,
    };
  },
  methods: {
    getRandomString(length) {
      var randomChars =
        "1234567890ABCDEFGHIJKLMNOPQRSTZabcdefghijklmnopstz-_@#$.";
      var result = "";
      for (var i = 0; i < length; i++) {
        result += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      return result;
    },
    setIntercomToken() {
      let intercomToken = localStorage.getItem("intercomToken");
      if (!intercomToken) {
        this.intercomToken = this.getRandomString(30);
        localStorage.setItem("intercomToken", this.intercomToken);
      } else {
        this.intercomToken = intercomToken;
      }
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        this.setIntercomToken();
        let legalExclude = "/legal/";
        if (to.name !== "LiveTracking" && !to.fullPath.includes(legalExclude)) {
          this.$intercom.boot({
            user_id: `General user contact: ${this.intercomToken}`,
          });
        } else {
          this.$intercom.shutdown();
        }
      },
      immediate: true,
    },
  },
  created() {},
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.v-main__wrap {
  background: whitesmoke;
}
</style>
